import React, { useEffect, useState } from 'react';
import logo from './logo.svg';
import './App.css';
import { useMoralis } from "react-moralis";
import { Button } from 'react-bootstrap';
import Moralis from 'moralis/types';


function App() {
  const { authenticate, isAuthenticated, isAuthenticating, user, account, logout } = useMoralis();
  
    const [name, setName] = useState('');
    const [id, setId] = useState('');
    const [surname, setSurname] = useState('');
    const [address, setAddress] = useState('');
    const [newName, setNewName] = useState('');
    const [newSurname, setNewSurname] = useState('');
    const [email, setEmail] = useState('');
    const [number, setNumber] = useState('');
    useEffect(() => {
    if (isAuthenticated) {
      // add your logic here
      console.log(account)
      console.log(user!.get("ethAddress"));
      // const userVerify = user!.get("ethAddress")
            // let formdata = new FormData();

            // formdata.append('address', '0x23FFbcD66BEA8C05f55e36d61b083826BB8F3CC5')
            // fetch(`https://exchange.h2o-securities.com/api/verify`, {
            //     method:"POST",
            //     headers:{
            //          'authToken' : 'eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJ1c2VyIjoid2Vic2l0ZSIsIm5hbWUiOiJ3ZWJzaXRlIiwiQVBJX1RJTUUiOjE2NTI3ODgzNTV9.IIF3FsHx4OPo8Cic3Wk5XzC11K6Z8m5Sg1u3gzU8hjU',
            //         'access-control-allow-origin': '*'
            //     }, 
            //   body:formdata
               
            // }).then((putresponse)=> putresponse.json())
            // .then((putresponse) => {
            //     console.log(putresponse)
            //     if(putresponse.status == false){
            //       alert(putresponse.message)
            //     }
            //     else{
            //       setId(putresponse[0].id)
            //     setName(putresponse[0].name)
            //     setSurname(putresponse[0].surname)
            //     }
            //     // putresponse[0]
            //     // setShouldShow(true)
               
              
            // })
            // .catch((error) => {
            //   console.error(error);
            // });
          
      
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAuthenticated]);

   
    const login = async () => {
      if (!isAuthenticated) {

        await authenticate({signingMessage: "Log in using Moralis" })
          .then(function (user) {
            console.log("logged in user:", user);
            console.log(user!.get("ethAddress"));
            const userVerify = user!.get("ethAddress")
            setAddress(user!.get("ethAddress"))
            let formdata = new FormData();

            formdata.append('address', userVerify)
            fetch(`https://exchange.h2o-securities.com/api/verify`, {
                method:"POST",
                headers:{
                     'authToken' : 'eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJ1c2VyIjoid2Vic2l0ZSIsIm5hbWUiOiJ3ZWJzaXRlIiwiQVBJX1RJTUUiOjE2NTI3ODgzNTV9.IIF3FsHx4OPo8Cic3Wk5XzC11K6Z8m5Sg1u3gzU8hjU',
                    'access-control-allow-origin': '*'
                }, 
              body:formdata
               
            }).then((putresponse)=> putresponse.json())
            .then((putresponse) => {
                console.log(putresponse)
                if(putresponse.status == false){
                  alert(putresponse.message)
                }
                else{
                  setId(putresponse[0].id)
                setName(putresponse[0].name)
                setSurname(putresponse[0].surname)
                }
                // putresponse[0]
                // setShouldShow(true)
               
              
            })
            .catch((error) => {
              console.error(error);
            });
          
          })
          .catch(function (error) {
            console.log(error);
          });
      }
    }

    const check = () =>{
      if(name === ''){
        if(newName != ''){
          setName(newName)
        }
        else{
          alert('Please your First Name')
        }

      }
      else if(surname === ''){
        if(newSurname != ''){
          setSurname(newSurname)
        }
        else{
          alert('Please your Last Name')
        }

      }
    
      else if(email ==''){
        alert('Please your email')
      }
      else if(number ==''){
        alert('Please your contact number')
      }
      else(
        update()
      )
    }
    const update = async () => {

      
      let formdata = new FormData();

            formdata.append('id', id)
            formdata.append('name', name)
            formdata.append('surname', surname)
            formdata.append('address', address)
            formdata.append('new_name', newName)
            formdata.append('new_surname', newSurname)
            formdata.append('email', email)
            formdata.append('number', number)
            fetch(`https://exchange.h2o-securities.com/api/verify`, {
                method:"PUT",
             
                headers:{
                     'authToken' : 'eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJ1c2VyIjoid2Vic2l0ZSIsIm5hbWUiOiJ3ZWJzaXRlIiwiQVBJX1RJTUUiOjE2NTI3ODgzNTV9.IIF3FsHx4OPo8Cic3Wk5XzC11K6Z8m5Sg1u3gzU8hjU',
                
                }, 
              // body:JSON.stringify(formdata)
              body: JSON.stringify({'id':id, 'name':name,'surname':surname,'address':address,'new_name':newName,'new_surname':newSurname, 'email':email,'number':number})
               
            }).then((putresponse)=> putresponse.json())
            .then((putresponse) => {
                console.log(putresponse)
                if(putresponse == true){
                  alert('User Updated')
                  setName('')
                  setId('')
                  setSurname('')
                  setAddress('')
                  setNewName('')
                  setNewSurname('')
                  setEmail('')
                  setNumber('')
                  logOut()
                }
                else{
                  alert('Failed to update user')
                }
                // setShouldShow(true)
               
              
            })
            .catch((error) => {
              console.error(error);
            });
    }

    const logOut = async () => {
      await logout();
      console.log("logged out");
    }


  return (
    <div className="App">
       <h1>My Details
       </h1>
        {isAuthenticated == false ?    
        <button onClick={login}>Connect wallet</button>
        
        :
        <div>
          <button onClick={logOut} disabled={isAuthenticating}>Logout</button>
          <div>
            <h1>Verify Details</h1>
            <div className="App-user">
              <h2>Account:</h2>
              <p style={{marginLeft:5, alignSelf:'center'}}>{address}</p>
            </div>
            <div className="App-user">
              <h2>First Name:</h2>
              <p style={{marginLeft:5, alignSelf:'center'}}>{name}</p>
              
            </div>
            <input type="text" value={newName} onChange={e => setNewName(e.target.value)}/>
            <div className="App-user">
              <h2>Last Name:</h2>
              <p style={{marginLeft:5, alignSelf:'center'}}>{surname}</p>
            </div>
            <input type="text" value={newSurname} onChange={e => setNewSurname(e.target.value)}/>
            <div className="App-user">
              <h2>Email:</h2>
            </div>
            <input type="text" value={email} onChange={e => setEmail(e.target.value)}/>
            <div className="App-user">
              <h2>Phone:</h2>
            </div>
            <input type="text" value={number} onChange={e => setNumber(e.target.value)}/>
          </div>
          
          <h1>Edit My Details</h1>
          <button onClick={check} >Update Details</button>
        </div>
      }
    </div>
  );
}

export default App;
